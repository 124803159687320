import React, { useState, useEffect } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { HiCheck } from 'react-icons/hi';

export default function TableCheckboxCell({ getValue, row, column, table }) {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;

  const [checked, setChecked] = useState(initialValue);

  useEffect(() => {
    if (typeof initialValue === 'string') {
      setChecked(initialValue.toLowerCase() === 'true');
    } else {
      setChecked(initialValue);
    }
  }, [initialValue]);

  const onCheckboxChange = (e) => {
    const newValue = !!e;
    setChecked(newValue);
    tableMeta?.updateData(row.index, column.id, newValue);
  };

  return (
    <div
      key={columnMeta?.label}
      className="flex p-2 hover:bg-platform-ainc-grey-200 w-full">
      <Checkbox.Root
        id={`checkbox_${columnMeta?.label}`}
        className="flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-lg border border-platform-primary-grey-200 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
        checked={checked}
        onCheckedChange={onCheckboxChange}>
        <Checkbox.Indicator>
          <HiCheck />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label
        htmlFor={`checkbox_${columnMeta?.label}`}
        className="pl-1 w-maxFull">
        {columnMeta?.label}
      </label>
    </div>
  );
}
