import React, { forwardRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { HiX } from 'react-icons/hi';

const BlockUserModal = forwardRef(({ setShowModal, admin, users }, ref) => {
  const handleBlock = () => {
    console.log(admin);
  };

  return (
    <div ref={ref}>
      <Dialog.Content className="w-[40vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="relative w-auto my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
              <div className="text-3xl font-bold text-platform-primary-grey-800">
                Block Users
              </div>
              <button
                type="button"
                aria-label="modal-open-button"
                className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800  border-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800"
                onClick={() => {
                  setShowModal(false);
                }}>
                <HiX />
              </button>
            </div>
            <div className="relative p-6 flex-auto max-h-[22rem] overflow-y-auto">
              The following users will be blocked in Auth0 and unable to access
              the AdviseInc Platform:
              <div>
                {users.map((u) => (
                  <li key={u.id}>{u.email}</li>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-between p-6 border-t border-solid border-platform-primary-grey-200 rounded-b">
              <button
                className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                type="button"
                onClick={() => {
                  setShowModal(false);
                }}>
                Cancel
              </button>
              <button
                className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                type="button"
                aria-label="handle-block-button"
                onClick={() => handleBlock()}>
                Block Users
              </button>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </div>
  );
});

export default BlockUserModal;
