/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export default function RolesBulkMultiSelectDropdown({
  usersToApprove,
  setUsersToApprove,
  roleAttributes,
  defaultValues,
}) {
  const [state, setState] = useState({
    optionSelected: null,
  });
  // useEffect(() => {
  //   if (defaultValues) {
  //     setState({
  //       optionSelected: defaultValues,
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (defaultValues && Array.isArray(defaultValues)) {
      const initialSelectedOptions = defaultValues.map((item) => {
        const matchingOption = roleAttributes.find(
          (option) => option.value === item,
        );
        return matchingOption;
      });
      setState({
        optionSelected: initialSelectedOptions,
      });

      setUsersToApprove(
        usersToApprove.map((user) => {
          const selectedValues = initialSelectedOptions
            ? initialSelectedOptions.map((option) => option.value)
            : [];

          // Update roleAttributes based on selected values
          user.roles = user.roles.filter((attr) =>
            selectedValues.includes(attr),
          );

          // Add any new selected values to roleAttributes
          initialSelectedOptions.forEach((value) => {
            if (!user.roles.includes(value.value)) {
              user.roles.push(value.value);
            }
          });
          return user;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (selected) => {
    setState({
      optionSelected: selected,
    });

    // Update usersToApproveList based on the selected options
    setUsersToApprove(
      usersToApprove.map((user) => {
        const selectedValues = selected
          ? selected.map((option) => option.value)
          : [];

        // Update roleAttributes based on selected values
        user.roles = user.roles.filter((attr) => selectedValues.includes(attr));

        // Add any new selected values to roleAttributes
        selected.forEach((value) => {
          if (!user.roles.includes(value.value)) {
            user.roles.push(value.value);
          }
        });
        return user;
      }),
    );
  };

  return (
    <span className="d-inline-block w-full">
      <Select
        options={roleAttributes}
        isMulti
        isSearchable
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={handleChange}
        className="basic-multi-select"
        classNamePrefix="select"
        allowSelectAll
        value={state.optionSelected}
        // menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999, position: 'fixed' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    </span>
  );
}
