import React, { useState } from 'react';
import { useSelect } from 'downshift';
import * as Label from '@radix-ui/react-label';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useFormContext } from 'react-hook-form';

export default function UserPreferences() {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();

  const [selectedItem, setSelectedItem] = useState(getValues('defaultOrg'));

  const orgValues = ['org', 'group'];

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: orgValues,
    onSelectedItemChange: ({ selectedItem: selection }) => {
      setValue('defaultOrg', selection);
      setSelectedItem(selection);
    },
  });

  return (
    <div className="flex flex-col p-2">
      <div className="mx-2 mt-2 mb-3">
        <div className="flex justify-between">
          <Label.Root
            htmlFor="nickname"
            className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
            Nickname
          </Label.Root>
          {errors.nickname && (
            <div className="text-xs font-bold">This field is required</div>
          )}
        </div>
        <input
          className={`appearance-none block w-[18rem] py-3 px-4 leading-tight rounded-md border ${
            watch('nickname') === ''
              ? 'border-platform-primary-orange-800'
              : 'border-platform-primary-grey-200'
          } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
          type="text"
          id="nickname"
          placeholder="Nickname"
          {...register('nickname', { required: true })}
        />
      </div>
      <div className="mx-2 mt-2 mb-3">
        <div className="flex justify-between">
          <Label.Root
            htmlFor="firstname"
            className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold"
            {...getLabelProps()}>
            Default View
          </Label.Root>
          {errors.defaultOrg && (
            <div className="text-xs font-bold">A selection is required</div>
          )}
        </div>
        <div
          className={`flex w-[18rem] justify-between items-center cursor-pointer py-3 px-4 leading-tight rounded-t-md border border-platform-primary-orange-800 bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 ${
            !isOpen && 'rounded-b-md'
          }`}
          {...getToggleButtonProps()}>
          <div>{selectedItem || 'Choose...'}</div>
          <div className="flex items-center px-2">
            {isOpen ? (
              <HiChevronUp className="text-xl" />
            ) : (
              <HiChevronDown className="text-xl" />
            )}
          </div>
        </div>
        <div
          className={`flex flex-col absolute w-[18rem] bg-white max-h-80 rounded-b-md border border-platform-primary-grey-800 ${
            !isOpen && 'hidden'
          }`}
          {...getMenuProps()}>
          {isOpen &&
            orgValues.map((item, index) => (
              <div
                className={`${
                  highlightedIndex === index &&
                  'bg-platform-primary-orange-800 text-white'
                }
              py-2 px-3
                ${
                  selectedItem === item && 'font-bold shadow-sm flex flex-col'
                }`}
                key={item}
                {...getItemProps({ item, index })}>
                <span>{item}</span>
              </div>
            ))}
        </div>
      </div>
      <div className="mx-2 mt-2 mb-3">
        <div className="flex justify-between">
          <Label.Root
            htmlFor="defaultLandingPage"
            className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
            Default Landing Page
          </Label.Root>
          {errors.defaultLandingPage && (
            <div className="text-xs font-bold">This field is required</div>
          )}
        </div>
        <input
          className={`appearance-none block w-[18rem] py-3 px-4 leading-tight rounded-md border ${
            watch('defaultLandingPage') === ''
              ? 'border-platform-primary-orange-800'
              : 'border-platform-primary-grey-200'
          } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
          type="text"
          id="defaultLandingPage"
          placeholder="Platform Page Default Landing Page"
          {...register('defaultLandingPage', { required: true })}
        />
      </div>
      <div className="mx-2 mt-2 mb-3">
        <div className="flex justify-between">
          <Label.Root
            htmlFor="appType"
            className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
            Default App
          </Label.Root>
          {errors.appType && (
            <div className="text-xs font-bold">This field is required</div>
          )}
        </div>
        <input
          className={`appearance-none block w-[18rem] py-3 px-4 leading-tight rounded-md border ${
            watch('appType') === ''
              ? 'border-platform-primary-orange-800'
              : 'border-platform-primary-grey-200'
          } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
          type="text"
          id="appType"
          placeholder="Default App"
          {...register('appType', { required: true })}
        />
      </div>
    </div>
  );
}
