import React, { useState, useEffect } from 'react';

export default function MultiInput({
  fieldName,
  usersToApprove,
  setUsersToApprove,
  defaultValue = '',
  placeholder = null,
}) {
  const [userInput, setUserInput] = useState(defaultValue);

  useEffect(() => {
    setUserInput(defaultValue);
  }, [defaultValue]);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
    const updatedUsers = usersToApprove.map((user) => {
      user[fieldName] = event.target.value;
      return user;
    });
    setUsersToApprove(updatedUsers);
  };

  return (
    <div className="flex group">
      <input
        type="text"
        id={`${fieldName}-input`}
        placeholder={placeholder ?? fieldName}
        value={userInput}
        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        onChange={handleInputChange}
      />
    </div>
  );
}
