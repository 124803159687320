import React from 'react';
import { umVersion } from '../version-config';

function Footer({ textStyle = 'text-white' }) {
  return (
    <footer
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '60px',
        lineHeight: '30px',
        zIndex: 1000,
      }}>
      <div className="justify-center">
        <span className={textStyle}>
          <small>
            Copyright {new Date().getFullYear()} AdviseInc Ltd. Version{' '}
            {umVersion.version}
          </small>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
