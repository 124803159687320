import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as Accordion from '@radix-ui/react-accordion';

import {
  FaBriefcase,
  FaChartBar,
  FaCircle,
  FaDoorOpen,
  FaHome,
} from 'react-icons/fa';
import { FiChevronRight, FiUserPlus } from 'react-icons/fi';
import Logo from '../assets/AdviseInc_Logo_WHITE.png';
import Footer from './Footer';

export default function SideBar() {
  const { pathname } = useLocation();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen pt-4 drop-shadow-xl bg-platform-primary-orange-800 font-main font-medium text-xs tracking-tight text-platform-secondary-grey-800">
      <div className="mx-2">
        <img src={Logo} alt="" width="170px" />
      </div>
      <div className="pt-2 pb-4 space-y-1 text-sm text-platform-ainc-grey-400">
        <div className="py-3 px-1">
          <hr className="border-1" />
        </div>
        <div
          key="dashboard"
          className="px-1 py-1 mx-1 rounded-md text-xs uppercase font-bold cursor-default">
          <div className="list-none">
            <div className="text-uppercase">User Management</div>
          </div>
        </div>
        <NavLink to="/home">
          <div
            key="home"
            className={`py-1 hover:bg-platform-ainc-grey-200 hover:text-platform-primary-grey-800 mx-1 rounded-md ${
              pathname === '/home' &&
              'text-platform-primary-orange-800 bg-platform-tertiary-grey-200'
            }`}>
            <div className="flex items-center p-2 space-x-5 rounded-md">
              <FaHome />
              <div>Home</div>
            </div>
          </div>
        </NavLink>
        <Accordion.Root type="single" collapsible className="">
          <Accordion.Item value="users" className="py-1">
            <Accordion.Header>
              <Accordion.Trigger className="group py-1 px-3 data-[state=closed]:hover:bg-platform-ainc-grey-200 data-[state=closed]:hover:text-platform-primary-grey-800 mx-1 data-[state=closed]:rounded-md data-[state=open]:rounded-t-md data-[state=open]:text-platform-primary-orange-800 data-[state=open]:bg-platform-tertiary-grey-200 data-[state=closed]:hover:bg-platform-ainc-grey-200 data-[state=closed]:hover:text-platform-primary-grey-800">
                <div className="flex items-center px-2 py-1 space-x-5">
                  <div className="justify-start">
                    <FiUserPlus /> <FiUserPlus />
                  </div>
                  <div className="flex-1">Users</div>
                  <div className="text-2xl justify-end items-center">
                    <FiChevronRight className="pl-2 ml-auto h-6 w-6 group-data-[state=open]:rotate-90" />
                  </div>
                </div>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="p-1 mx-1 rounded-b-md text-platform-primary-orange-800 bg-platform-tertiary-grey-200">
              <div className="flex-col">
                <button
                  className="hover:bg-platform-ainc-grey-200 hover:text-platform-primary-grey-800"
                  key="all-users"
                  type="button"
                  onClick={() => {
                    navigate('/all-users');
                  }}>
                  <div className="flex items-center px-2 py-1 space-x-5">
                    <FaCircle className="h-2 w-2" />
                    <div className="flex-1">All Users</div>
                    <div className="text-2xl justify-end">
                      <FiChevronRight className="pl-2" />
                    </div>
                  </div>
                </button>
              </div>
            </Accordion.Content>
            <Accordion.Content className="p-1 mx-1 rounded-b-md text-platform-primary-orange-800 bg-platform-tertiary-grey-200">
              <div className="flex-col">
                <button
                  className="hover:bg-platform-ainc-grey-200 hover:text-platform-primary-grey-800"
                  key="users-to-activate"
                  type="button"
                  onClick={() => {
                    navigate('/users-to-activate');
                  }}>
                  <div className="flex items-center px-2 py-1 space-x-5">
                    <FaCircle className="h-2 w-2" />
                    <div className="flex-1">Users to be Activated</div>
                    <div className="text-2xl justify-end">
                      <FiChevronRight className="pl-2" />
                    </div>
                  </div>
                </button>
              </div>
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item value="roles" disabled>
            <Accordion.Header>
              <Accordion.Trigger className="group py-1 px-3 data-[state=closed]:hover:bg-platform-ainc-grey-200 data-[state=closed]:hover:text-platform-primary-grey-800 mx-1 data-[state=closed]:rounded-md data-[state=open]:rounded-t-md data-[state=open]:text-platform-primary-orange-800 data-[state=open]:bg-platform-tertiary-grey-200 data-[state=closed]:hover:bg-platform-ainc-grey-200 data-[state=closed]:hover:text-platform-primary-grey-800">
                <div className="flex items-center px-2 py-1 space-x-5">
                  <FaBriefcase />
                  <div className="flex-1">Roles</div>
                  <div className="text-2xl justify-end items-center">
                    <FiChevronRight className="pl-2 ml-auto h-6 w-6 group-data-[state=open]:rotate-90" />
                  </div>
                </div>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="p-1 mx-1 rounded-b-md text-platform-primary-orange-800 bg-platform-tertiary-grey-200">
              <div className="flex-col">
                <button
                  className="hover:bg-platform-ainc-grey-200 hover:text-platform-primary-grey-800"
                  key="create-roles"
                  type="button"
                  onClick={() => {
                    navigate('/users');
                  }}>
                  <div className="flex items-center px-2 py-1 space-x-5">
                    <FaCircle className="h-2 w-2" />
                    <div className="flex-1">Create Roles</div>
                    <div className="text-2xl justify-end">
                      <FiChevronRight className="pl-2" />
                    </div>
                  </div>
                </button>
                <button
                  className="hover:bg-platform-ainc-grey-200 hover:text-platform-primary-grey-800"
                  key="assign-roles"
                  type="button"
                  onClick={() => {
                    navigate('/pre-registration');
                  }}>
                  <div className="flex items-center px-2 py-1 space-x-5">
                    <FaCircle className="h-2 w-2" />
                    <div className="flex-1">Assign Roles</div>
                    <div className="text-2xl justify-end">
                      <FiChevronRight className="pl-2" />
                    </div>
                  </div>
                </button>
              </div>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
        <div className="py-3 px-1">
          <hr className="border-1" />
        </div>
        <div
          key="analytics"
          className="px-1 py-1 mx-1 rounded-md text-xs uppercase font-bold cursor-default">
          <div className="list-none">
            <div className="text-uppercase">Analytics</div>
          </div>
        </div>
        <NavLink to="/logs">
          <div
            key="logs"
            className={`py-1 hover:bg-platform-ainc-grey-200 hover:text-platform-primary-grey-800 mx-1 rounded-md ${
              pathname === '/logs' &&
              'text-platform-primary-orange-800 bg-platform-tertiary-grey-200'
            }`}>
            <div className="flex items-center p-2 space-x-3 rounded-md">
              <FaChartBar />
              <div>Usage Logs</div>
            </div>
          </div>
        </NavLink>
        <div className="py-3 px-1">
          <hr className="border-1 " />
        </div>
        <div className="py-1 hover:bg-platform-ainc-grey-200 hover:text-platform-primary-grey-800 mx-1 rounded-md">
          <button
            key="logs"
            type="button"
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }>
            <div className="flex items-center p-2 space-x-3 rounded-md">
              <FaDoorOpen />
              <div>Logout</div>
            </div>
          </button>
        </div>
        <div className="flex justify-center pl-1">
          <Footer />
        </div>
      </div>
    </div>
  );
}
