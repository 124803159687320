import React from 'react';
import { Outlet } from 'react-router-dom';
import TitleBar from './TitleBar';
import SideBar from './SideBar';

export default function PageLayout({ admin }) {
  return (
    <div className="flex">
      <div className="w-168">
        <SideBar admin={admin} />
      </div>
      <div className="flex flex-col w-maxFull max-h-screen">
        <TitleBar />
        <Outlet />
      </div>
    </div>
  );
}
