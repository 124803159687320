export const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const auth0Token = process.env.REACT_APP_AUTH0_TOKEN;
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const auth0ConnectionName = process.env.REACT_APP_AUTH0_CONNECTION_NAME;

export const headers = {
  'Content-Type': 'application/json',
  authorization: 'allow',
  'Access-Control-Allow-Origin': 'True',
};
export const validAdviseIncEmail =
  /^[a-zA-Z0-9\._:$!%-]+@[a-zA-Z0-9\.-]*(adviseinc)\.[a-zA-Z\\.]{2,5}$/; // eslint-disable-line

export const auth0Variables = {
  authToken: auth0Token,
  auth0Domain,
  auth0ClientId,
  connectionName: auth0ConnectionName,
};

// Commented out attributes are attributes that exist but are not used or shouldn't be allowed to be added by cops
export const qliksenseAttributes = [
  {
    id: 1,
    label: 'Security Licence Permission',
    mandatory: true,
    value: '^security_licence_permission',
  },
  {
    id: 2,
    label: 'Security Export Permission',
    mandatory: false,
    value: '^security_export_permission',
  },
  {
    id: 3,
    label: 'AdviseInc Platform Stream',
    mandatory: true,
    value: '^adviseinc_platform_stream',
  },
  {
    id: 4,
    label: 'AdviseInc Platform',
    mandatory: true,
    value: '^control_tower_app',
  },
  // {
  //   id: 5,
  //   label: 'AdviseInc Engineering',
  //   mandatory: false,
  //   value: '^adviseinc_engineering',
  // },
  // {
  //   id: 6,
  //   label: 'AdviseInc Testing',
  //   mandatory: false,
  //   value: '^adviseinc_testing',
  // },
  {
    id: 7,
    label: 'Catalogue Check Stream',
    mandatory: false,
    value: '^catalogue_check_stream',
  },
  {
    id: 8,
    label: 'Catalogue Check App (BANES)',
    mandatory: false,
    value: '^catalogue_check_app_banes',
  },
  {
    id: 9,
    label: 'Catalogue Check App (Notts)',
    mandatory: false,
    value: '^catalogue_check_app_notts',
  },
  {
    id: 10,
    label: 'Inventory Management Stream',
    mandatory: false,
    value: '^inventory_management_stream',
  },
  {
    id: 11,
    label: 'Inventory Management App (BANES)',
    mandatory: false,
    value: '^inventory_management_app_banes',
  },
  {
    id: 12,
    label: 'Workplan Stream',
    mandatory: false,
    value: '^workplan_stream',
  },
  {
    id: 13,
    label: 'Workplan App (BANES)',
    mandatory: false,
    value: '^workplan_app_banes',
  },
  // {
  //   id: 14,
  //   label: 'Workplan Stream (Sheffield)',
  //   mandatory: false,
  //   value: '^workplan_stream_sheffield',
  // },
  // {
  //   id: 15,
  //   label: 'Workplan App (Sheffield)',
  //   mandatory: false,
  //   value: '^workplan_app_sheffield',
  // },
  // {
  //   id: 16,
  //   label: 'Workplan App (Sheffield inc. SelfServe)',
  //   mandatory: false,
  //   value: '^workplan_app_sheffieldIncselfserve',
  // },
  // {
  //   id: 17,
  //   label: 'Workplan App (Sheffield Plus)',
  //   mandatory: false,
  //   value: '^workplan_app_sheffield_plus',
  // },
  {
    id: 18,
    label: 'Inventory Management App',
    mandatory: false,
    value: '^inventory_management_app',
  },
];

export const pageTitles = [
  { page: '/', text: 'Welcome' },
  { page: '/users', text: 'Manage current users' },
  { page: '/pre-registration', text: 'Manage pre-registered users' },
  { page: '/roles', text: 'Manage user roles' },
];

export const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const weekdayNamesShort = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];
