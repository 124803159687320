import React, { useState, useEffect } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { HiCalendar, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { PatternFormat } from 'react-number-format';
import { useDayzed } from 'dayzed';

import { isValid as isDateValid, format, parse } from 'date-fns';
import { monthNamesShort, weekdayNamesShort } from '../../app/lookups';

export default function TableDateCell({ getValue, row, column, table }) {
  const initialValue = getValue();
  const tableMeta = table.options.meta;
  const [open, setOpen] = useState(false);
  const [inputOnDateSelectedValue, setInputOnDateSelectedValue] = useState();
  const [formattedDate, setFormattedDate] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const handleDateInput = (inputString) => {
    if (inputString) {
      const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const match = inputString.match(dateRegex);
      if (match) {
        const parsedDate = parse(inputString, 'dd/MM/yyyy', new Date());

        if (parsedDate && isDateValid(parsedDate)) {
          const dateString = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
          setSelectedDate(dateString);
          setInputOnDateSelectedValue(dateString);
          setFormattedDate(format(parsedDate, 'dd/MM/yyyy'));
          tableMeta?.updateData(row.index, column.id, dateString);
        }
      }
    }
  };

  useEffect(() => {
    if (initialValue) {
      const parsedDate = parse(initialValue, 'yyyy-MM-dd', new Date());
      if (parsedDate && isDateValid(parsedDate)) {
        const dateString = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
        setSelectedDate(dateString);
        setInputOnDateSelectedValue(dateString);
        setFormattedDate(format(parsedDate, 'dd/MM/yyyy'));
        tableMeta?.updateData(row.index, column.id, dateString);
      } else {
        const newParsedDate = parse(
          initialValue,
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
          new Date(),
        );
        if (newParsedDate && isDateValid(newParsedDate)) {
          const dateString = format(newParsedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
          setSelectedDate(dateString);
          setInputOnDateSelectedValue(dateString);
          setFormattedDate(format(newParsedDate, 'dd/MM/yyyy'));
        }
      }
    }
  }, [column.id, initialValue, row.index, tableMeta]);

  const handleOnDateSelected = ({ date }) => {
    const validDate = isDateValid(date)
      ? format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'")
      : '';
    setInputOnDateSelectedValue(validDate);
    setFormattedDate(format(date, 'dd/MM/yyyy'));
    tableMeta?.updateData(row.index, column.id, validDate);
  };

  useEffect(() => {
    setSelectedDate(inputOnDateSelectedValue);
  }, [inputOnDateSelectedValue]);

  const handleClearDate = () => {
    setSelectedDate('');
    setFormattedDate('');
    tableMeta?.updateData(row.index, column.id, '');
  };

  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    selected: selectedDate ? new Date(selectedDate) : undefined,
    onDateSelected: handleOnDateSelected,
    showOutsideDays: true,
    minDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
  });

  return (
    <div className="flex group">
      <PatternFormat
        format="%%/%%/%%%%"
        patternChar="%"
        placeholder="dd/mm/yyyy"
        value={formattedDate}
        onChange={(e) => handleDateInput(e.target.value)}
        onBlur={(e) => handleDateInput(e.target.value)}
        className={`appearance-none block w-[240px] py-3 px-4 leading-tight rounded-l-md border ${
          selectedDate === ''
            ? 'border-platform-primary-orange-800'
            : 'border-platform-primary-grey-200'
        } bg-white text-xs font-medium text-platform-primary-grey-800 group-hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
      />

      <Popover.Root open={open} onOpenChange={() => setOpen(!open)}>
        <Popover.Trigger asChild>
          <button
            type="button"
            aria-label="calendar-button"
            className={`border-t border-b border-r ${
              selectedDate === ''
                ? 'border-platform-primary-orange-800 text-platform-primary-orange-800'
                : 'border-platform-primary-grey-200 text-platform-primary-grey-200'
            } w-25 rounded-r-md p-2 font-bold group-hover:bg-platform-primary-orange-800 group-hover:text-white bg-white`}>
            <HiCalendar />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="rounded-lg bg-white border border-platform-primary-grey-200"
            side="top">
            <div>
              {calendars.length ? (
                <div className="max-w-[300px] text-center">
                  {calendars.map((calendar) => (
                    <div key={`${calendar.month}-${calendar.year}`}>
                      <div className="flex justify-between p-2 border-b border-platform-primary-grey-200">
                        <button
                          type="button"
                          aria-label="chevron-left-button"
                          {...getBackProps({ calendars })}
                          className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                          <div className="flex items-center">
                            <HiChevronLeft className="text-2xl" />
                          </div>
                        </button>
                        <div className="flex items-center font-bold">
                          {monthNamesShort[calendar.month]} {calendar.year}
                        </div>
                        <button
                          type="button"
                          {...getForwardProps({ calendars })}
                          className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                          <div className="flex items-center">
                            <HiChevronRight className="text-2xl" />
                          </div>
                        </button>
                      </div>
                      <div className="block w-auto mx-1 pb-1">
                        {weekdayNamesShort.map((weekday) => (
                          <div
                            key={`${calendar.month}-${calendar.year}-${weekday}`}
                            className="inline-block bg-transparent w-[calc(100%/7)] font-bold">
                            {weekday}
                          </div>
                        ))}
                        {calendar.weeks.map((week, weekIndex) =>
                          week.map((dateObj, index) => {
                            const key = `${calendar.month}-${calendar.year}-${weekIndex}-${index}`;
                            if (!dateObj) {
                              return (
                                <div
                                  className="inline-block bg-transparent w-[calc(100%/7)]"
                                  key={key}
                                />
                              );
                            }
                            const { date, selected, selectable, today } =
                              dateObj;
                            return (
                              <div
                                className="inline-block w-[calc(100%/7)] px-0.5"
                                key={key}>
                                <button
                                  type="button"
                                  className={`w-maxFull mx-1 my-0.5 px-2.5 py-1 align-middle items-center text-sm rounded-md ${
                                    today && selected
                                      ? 'text-white bg-platform-primary-orange-800 border border-platform-primary-orange-800 hover:bg-platform-primary-orange-800'
                                      : today
                                      ? 'text-platform-primary-orange-800 border border-platform-primary-orange-800'
                                      : selected
                                      ? 'bg-platform-primary-orange-800 text-white hover:bg-platform-primary-orange-800'
                                      : !selectable
                                      ? 'text-platform-cta-error-800'
                                      : ''
                                  } hover:bg-platform-ainc-grey-400`}
                                  {...getDateProps({ dateObj })}>
                                  {date.getDate()}
                                </button>
                              </div>
                            );
                          }),
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div className="flex justify-between px-2 py-3 border-t border-platform-primary-grey-200">
                <button
                  onClick={handleClearDate}
                  className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                  type="button">
                  Clear Date
                </button>
                <button
                  disabled={selectedDate === ''}
                  onClick={() => setOpen(false)}
                  className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                  type="button">
                  Apply Date
                </button>
              </div>
            </div>
            <Popover.Arrow className="fill-platform-primary-grey-200" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}
