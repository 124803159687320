import React, { useState, useEffect } from 'react';
import Select from 'react-select';

export default function TableSelectCell({ getValue, row, column, table }) {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (initialValue && Array.isArray(initialValue)) {
      const initialSelectedOptions = initialValue.map((item) => {
        const matchingOption = columnMeta?.options.find(
          (option) => option.value === item,
        );
        return matchingOption;
      });
      setSelectedOptions(initialSelectedOptions);
    }
  }, [initialValue, columnMeta?.options]);

  const onSelectChange = (selected) => {
    setSelectedOptions(selected);
    const selectedValues = selected
      ? selected.map((option) => option.value)
      : [];
    tableMeta?.updateData(row.index, column.id, selectedValues);
  };

  return (
    <Select
      options={columnMeta?.options}
      isMulti
      isSearchable
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={onSelectChange}
      className="basic-multi-select"
      classNamePrefix="select"
      value={selectedOptions}
      // menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
}
