import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { pageTitles } from '../app/lookups';

export default function TitleBar() {
  const location = useLocation();
  const { user } = useAuth0();

  const titleText =
    location.pathname === '/home'
      ? `Hi ${user.nickname}. Welcome to the AdviseInc User Management Console`
      : pageTitles.find((el) => el.page === location.pathname)?.text;

  return (
    <div className="flex items-center justify-start drop-shadow-md h-14 bg-platform-primary-orange-800">
      <div className="text-left text-3xl font-bold text-platform-main-white-200 py-2 pl-1">
        {titleText}
      </div>
    </div>
  );
}
