import React, { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { HiCalendar, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { PatternFormat } from 'react-number-format';
import { isValid, format, parse } from 'date-fns';
import { useDayzed } from 'dayzed';
import { Controller } from 'react-hook-form';
import { monthNamesShort, weekdayNamesShort } from '../../app/lookups';

export default function SingleDateField({
  fieldId,
  setValue,
  reset,
  watch,
  control,
}) {
  const [open, setOpen] = useState(false);

  const handleDateInput = (inputString) => {
    const date = parse(inputString, 'dd/MM/yyyy', new Date());
    setValue(fieldId, isValid(date) ? inputString : '');
  };

  const handleOnDateSelected = ({ date }) => {
    setValue(fieldId, isValid(date) ? format(date, 'dd/MM/yyyy') : '');
  };

  const handleClearDate = () => {
    reset({ ...watch(), [fieldId]: '' });
  };

  const { calendars, getBackProps, getForwardProps, getDateProps } = {
    ...useDayzed({
      selected:
        watch(fieldId) !== ''
          ? parse(watch(fieldId), 'dd/MM/yyyy', new Date())
          : '',
      onDateSelected: handleOnDateSelected,
      showOutsideDays: true,
      minDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
    }),
  };

  return (
    <div className="flex group">
      <Controller
        control={control}
        name={fieldId}
        rules={{ required: true }}
        render={({ field }) => (
          <PatternFormat
            {...field}
            format="%%/%%/%%%%"
            patternChar="%"
            placeholder="dd/mm/yyyy"
            onChange={field.onChange}
            onBlur={(e) => handleDateInput(e.target.value)}
            className={`appearance-none block w-[240px] py-3 px-4 leading-tight rounded-l-md border ${
              watch(fieldId) === ''
                ? 'border-platform-primary-orange-800'
                : 'border-platform-primary-grey-200'
            } bg-white text-xs font-medium text-platform-primary-grey-800 group-hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
          />
        )}
      />
      <Popover.Root open={open} onOpenChange={() => setOpen(!open)}>
        <Popover.Trigger asChild>
          <button
            type="button"
            aria-label="calendar-button"
            className={`border-t border-b border-r ${
              watch(fieldId) === ''
                ? 'border-platform-primary-orange-800 text-platform-primary-orange-800'
                : 'border-platform-primary-grey-200 text-platform-primary-grey-200'
            } w-25 rounded-r-md p-2 font-bold group-hover:bg-platform-primary-orange-800 group-hover:text-white bg-white`}>
            <HiCalendar />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <div>
            <Popover.Content
              className="rounded-lg bg-white border border-platform-primary-grey-200"
              side="top">
              <div>
                {calendars.length ? (
                  <div className="max-w-[300px] text-center">
                    {calendars.map((calendar) => (
                      <>
                        <div className="flex justify-between p-2 border-b border-platform-primary-grey-200">
                          <button
                            type="button"
                            aria-label="chevron-left-button"
                            {...getBackProps({ calendars })}
                            className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                            <div className="flex items-center">
                              <HiChevronLeft className="text-2xl" />
                            </div>
                          </button>
                          <div className="flex items-center font-bold">
                            {monthNamesShort[calendar.month]} {calendar.year}
                          </div>
                          <button
                            type="button"
                            {...getForwardProps({ calendars })}
                            className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                            <div className="flex items-center">
                              <HiChevronRight className="text-2xl" />
                            </div>
                          </button>
                        </div>
                        <div
                          key={`${calendar.month}-${calendar.year}`}
                          className="block w-auto mx-1 pb-1">
                          {weekdayNamesShort.map((weekday) => (
                            <div
                              key={`${calendar.month}-${calendar.year}-${weekday}`}
                              className="inline-block bg-transparent w-[calc(100%/7)] font-bold">
                              {weekday}
                            </div>
                          ))}
                          {calendar.weeks.map((week, weekIndex) =>
                            week.map((dateObj, index) => {
                              const key = `${calendar.month}-${calendar.year}-${weekIndex}-${index}`;
                              if (!dateObj) {
                                return (
                                  <div
                                    className="inline-block bg-transparent w-[calc(100%/7)]"
                                    key={key}
                                  />
                                );
                              }
                              const { date, selected, selectable, today } =
                                dateObj;
                              return (
                                <div
                                  className="inline-block w-[calc(100%/7)] px-0.5"
                                  key={key}>
                                  <button
                                    type="button"
                                    className={`w-maxFull mx-1 my-0.5 px-2.5 py-1 align-middle items-center text-sm rounded-md ${
                                      today && selected
                                        ? 'text-white bg-platform-primary-orange-800 border border-platform-primary-orange-800 hover:bg-platform-primary-orange-800'
                                        : today
                                        ? 'text-platform-primary-orange-800 border border-platform-primary-orange-800'
                                        : selected
                                        ? 'bg-platform-primary-orange-800 text-white hover:bg-platform-primary-orange-800'
                                        : !selectable
                                        ? 'text-platform-cta-error-800'
                                        : ''
                                    } hover:bg-platform-ainc-grey-400`}
                                    {...getDateProps({ dateObj })}>
                                    {date.getDate()}
                                  </button>
                                </div>
                              );
                            }),
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                ) : null}
                <div className="flex justify-between px-2 py-3 border-t border-platform-primary-grey-200">
                  <button
                    onClick={handleClearDate}
                    className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                    type="button">
                    Clear Date
                  </button>
                  <button
                    disabled={watch(fieldId) === ''}
                    onClick={() => setOpen(false)}
                    className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                    type="button">
                    Apply Date
                  </button>
                </div>
              </div>
              <Popover.Arrow className="fill-platform-primary-grey-200" />
            </Popover.Content>
          </div>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}
