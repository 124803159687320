import React, { useState, useEffect } from 'react';
import Select from 'react-select';

export default function SenseAttributesBulkMultiSelectDropdown({
  usersToApprove,
  setUsersToApprove,
  qliksenseAttributes,
  defaultValues,
}) {
  const [state, setState] = useState({
    optionSelected: null,
  });

  useEffect(() => {
    if (defaultValues && Array.isArray(defaultValues)) {
      const initialSelectedOptions = defaultValues.map((item) => {
        const matchingOption = qliksenseAttributes.find(
          (option) => option.value === item,
        );
        return matchingOption;
      });
      setState({
        optionSelected: initialSelectedOptions,
      });

      setUsersToApprove(
        usersToApprove.map((user) => {
          const selectedValues = initialSelectedOptions
            ? initialSelectedOptions.map((option) => option.value)
            : [];

          // Update roleAttributes based on selected values
          user.sense_attributes = user.sense_attributes.filter((attr) =>
            selectedValues.includes(attr),
          );

          // Add any new selected values to roleAttributes
          initialSelectedOptions.forEach((value) => {
            if (!user.sense_attributes.includes(value.value)) {
              user.sense_attributes.push(value.value);
            }
          });
          return user;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (selected) => {
    setState({
      optionSelected: selected,
    });

    // Update usersToApproveList based on the selected options
    setUsersToApprove(
      usersToApprove.map((user) => {
        const selectedValues = selected
          ? selected.map((option) => option.value)
          : [];

        // Update sense_attributes based on selected values
        user.sense_attributes = user.sense_attributes.filter((attr) =>
          selectedValues.includes(attr),
        );

        // Add any new selected values to sense_attributes
        selected.forEach((value) => {
          if (!user.sense_attributes.includes(value.value)) {
            user.sense_attributes.push(value.value);
          }
        });
        return user;
      }),
    );
  };

  return (
    <span className="d-inline-block w-full">
      <Select
        options={qliksenseAttributes}
        isMulti
        isSearchable
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={handleChange}
        className="basic-multi-select"
        classNamePrefix="select"
        allowSelectAll
        value={state.optionSelected}
        // menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </span>
  );
}
