import React, { useEffect, useState } from 'react';
import {
  HiCheck,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
  HiXCircle,
} from 'react-icons/hi';
import * as Checkbox from '@radix-ui/react-checkbox';
import { useFormContext } from 'react-hook-form';
import { useUsersAPI } from '../app/userContext';

export default function RolesDualListBox() {
  const { roles } = useUsersAPI();
  const [searchTerm, setSearchTerm] = useState('');

  const { setValue, getValues, watch } = useFormContext();

  const [availableRoles, setAvailableRoles] = useState(
    roles.filter((r) => !getValues('roles').includes(r.name)),
  );
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      setAvailableRoles(
        roles
          .filter((r) => !getValues('roles').includes(r.name))
          .filter((role) =>
            role.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase().replace(/[^0-9a-z ]/gi, '')),
          ),
      );
    } else {
      setAvailableRoles(
        roles.filter((r) => !getValues('roles').includes(r.name)),
      );
    }
  }, [getValues, roles, searchTerm]);

  return (
    <>
      <div className="flex mb-2">
        <button
          type="button"
          aria-label="circle-button"
          onClick={() => setSearchTerm('')}
          className="border-2 w-25 rounded-l-md p-2 font-bold hover:bg-platform-cta-error-800 border-platform-cta-error-800 hover:text-white bg-white text-platform-cta-error-800">
          <HiXCircle />
        </button>
        <input
          className="flex w-maxFull px-2 py-2 leading-tight rounded-r-md border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-grey-200 focus:ring-0"
          type="text"
          placeholder="Search Roles..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="flex mb-2 justify-between max-h-[22rem]">
        <div className="flex-col border border-platform-primary-grey-200 rounded-md w-[20rem] h-[15rem] overflow-y-auto">
          {availableRoles
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((r) => (
              <div
                key={r.name}
                className="flex p-1 hover:bg-platform-ainc-grey-200">
                <Checkbox.Root
                  id={`checkbox_${r.name}`}
                  className="flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-md border border-platform-primary-grey-200 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                  checked={selectedRoles.includes(r.name)}
                  onCheckedChange={() =>
                    selectedRoles.includes(r.name)
                      ? setSelectedRoles(
                          selectedRoles.filter((s) => s !== r.name),
                        )
                      : setSelectedRoles([...selectedRoles, r.name])
                  }>
                  <Checkbox.Indicator>
                    <HiCheck />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <label
                  htmlFor={`checkbox_${r.name}`}
                  className="pl-1 w-maxFull">
                  {r.name}
                </label>
              </div>
            ))}
        </div>
        <div className="m-auto px-2">
          <div className="flex flex-col py-2">
            <div className="pb-1">
              <button
                type="button"
                aria-label="chevron-button"
                disabled={
                  searchTerm.length > 0 ||
                  availableRoles.length === 0 ||
                  (selectedRoles.length > 0 &&
                    selectedRoles.every((r) => getValues('roles').includes(r)))
                }
                onClick={() => {
                  setValue(
                    'roles',
                    roles.map((r) => r.name),
                  );
                  setAvailableRoles([]);
                  setSelectedRoles([]);
                }}
                className="hover:bg-platform-cta-success-800 hover:text-white text-sm font-bold px-4 py-1 rounded-full bg-white text-platform-cta-success-800 border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
                <HiChevronDoubleRight className="text-2xl" />
              </button>
            </div>
            <div className="py-1">
              <button
                type="button"
                aria-label="selected-roles-button"
                disabled={
                  selectedRoles.length === 0 ||
                  selectedRoles.every((r) => watch('roles').includes(r))
                }
                onClick={() => {
                  setValue('roles', [
                    ...watch('roles'),
                    ...availableRoles
                      .filter((r) => selectedRoles.includes(r.name))
                      .map((r) => r.name),
                  ]);
                  setAvailableRoles(
                    availableRoles.filter(
                      (r) => !selectedRoles.includes(r.name),
                    ),
                  );
                  setSelectedRoles([]);
                }}
                className="hover:bg-platform-cta-success-800 hover:text-white text-sm font-bold px-4 py-1 rounded-full bg-white text-platform-cta-success-800 border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
                <HiChevronRight className="text-2xl" />
              </button>
            </div>
            <div className="py-1">
              <button
                type="button"
                aria-label="selected-available-roles-button"
                disabled={
                  watch('roles').length === 0 ||
                  selectedRoles.length === 0 ||
                  !selectedRoles.every((r) => watch('roles').includes(r))
                }
                onClick={() => {
                  setValue('roles', [
                    ...watch('roles').filter(
                      (r) => !selectedRoles.includes(r.name),
                    ),
                  ]);
                  setAvailableRoles([
                    ...availableRoles,
                    ...roles.filter((r) => selectedRoles.includes(r.name)),
                  ]);
                  setSelectedRoles([]);
                }}
                className="hover:bg-platform-cta-error-800 hover:text-white text-sm font-bold px-4 py-1 rounded-full bg-white text-platform-cta-error-800 border-platform-cta-error-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
                <HiChevronLeft className="text-2xl" />
              </button>
            </div>
            <div className="py-1">
              <button
                type="button"
                aria-label="selected-roles-list-button"
                disabled={
                  watch('roles').length === 0 ||
                  (selectedRoles.length > 0 &&
                    selectedRoles.every((r) => availableRoles.includes(r)))
                }
                onClick={() => {
                  setValue('roles', []);
                  setAvailableRoles(roles);
                  setSelectedRoles([]);
                }}
                className="hover:bg-platform-cta-error-800 hover:text-white text-sm font-bold px-4 py-1 rounded-full bg-white text-platform-cta-error-800 border-platform-cta-error-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
                <HiChevronDoubleLeft className="text-2xl" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex-col border border-platform-primary-grey-200 rounded-md w-[20rem] h-[15rem] overflow-y-auto">
          {watch('roles')
            .sort((a, b) => a.localeCompare(b))
            .map((r) => (
              <div key={r} className="flex p-1 hover:bg-platform-ainc-grey-200">
                <Checkbox.Root
                  id={`checkbox_${r}`}
                  className="flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-md border border-platform-primary-grey-200 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                  checked={selectedRoles.includes(r)}
                  onCheckedChange={() =>
                    selectedRoles.includes(r)
                      ? setSelectedRoles(selectedRoles.filter((s) => s !== r))
                      : setSelectedRoles([...selectedRoles, r])
                  }>
                  <Checkbox.Indicator>
                    <HiCheck />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <label htmlFor={`checkbox_${r}`} className="pl-1 w-maxFull">
                  {r}
                </label>
              </div>
            ))}
        </div>
      </div>
      <div className="border border-platform-primary-grey-200 rounded-md mb-2 p-1 h-[5rem] overflow-y-auto">
        <ul className="w-maxFull text-sm">
          {selectedRoles.map((r) =>
            roles
              .filter((role) => r === role.name)
              .map((s) => <li key={s.name}>{s.description}</li>),
          )}
        </ul>
      </div>
    </>
  );
}
