import React, { useRef, useState } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  HiCheck,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
  HiDotsHorizontal,
  HiMail,
  HiMinus,
  HiMinusCircle,
  HiPencil,
  HiX,
  HiTrash,
} from 'react-icons/hi';
import dateFormat from 'dateformat';
import * as Dialog from '@radix-ui/react-dialog';
import { format, parse } from 'date-fns';
import EditUserModal from './EditUserModal';
import SenseAttributesPopover from './SenseAttributesPopover';
import DeleteUserModal from './DeleteUserModal';
import ResetPasswordModal from './ResetPasswordModal';
import { useUsersAPI } from '../../app/userContext';
import BlockUserModal from './BlockUserModal';
import UsersTableBar from './UsersTableBar';

export default function MainTable({ admin, selectedUsers, setSelectedUsers }) {
  const columnHelper = createColumnHelper();
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showBlockedUserModal, setShowBlockedUserModal] = useState(false);

  const { mergedUsers } = useUsersAPI();

  const ref = useRef();

  const handleEditUser = (user) => {
    setShowEditUserModal(true);
    setSelectedUsers([user]);
  };

  const handleDeleteUser = (user) => {
    setShowDeleteUserModal(true);
    setSelectedUsers([user]);
  };

  const handleResetPassword = (user) => {
    setShowResetPasswordModal(true);
    setSelectedUsers([user]);
  };

  // eslint-disable-next-line no-unused-vars
  const customApiMultiFilter = (row, columnId, filterValue, api) => {
    if (filterValue.length === 0) {
      return row;
    }

    // splits based on separator and uses filter to remove all empty strings
    const filterValues = filterValue
      .split('&&')
      .filter((filter) => String(filter).trim())
      .map((value) => value.trim().toLowerCase());
    return filterValues.length === 0
      ? row
      : api
          .filter((t) =>
            filterValues.some((val) => t.name.toLowerCase().includes(val)),
          )
          .map((t) => t.id)
          .includes(row.getValue(columnId));
  };

  const customMultiFilter = (row, columnId, filterValue) => {
    if (filterValue.length === 0) {
      return row;
    }

    // splits based on separator and uses filter to remove all empty strings
    const filterValues = filterValue
      .split('&&')
      .filter((filter) => String(filter).trim())
      .map((value) => value.trim().toLowerCase());

    return filterValues.length === 0
      ? row
      : filterValues.some((val) =>
          String(row?.original[columnId])?.toLowerCase().includes(val),
        );
  };
  const handleBlockUser = (user) => {
    setShowBlockedUserModal(true);
    setSelectedUsers([user]);
  };

  const handleRowSelect = (rowId) => {
    setSelectedUsers(
      selectedUsers.includes(rowId)
        ? selectedUsers.filter((r) => r !== rowId)
        : [...selectedUsers, rowId],
    );
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'bg-green-300 bg-opacity-25'; // Active status is typically associated with green.
      case 'blocked':
        return 'bg-red-300 bg-opacity-25'; // Blocked status is strongly associated with red for warning.
      case 'awaiting activation':
        return 'bg-yellow-300 bg-opacity-25'; // Yellow signifies something pending or in progress.
      case 'awaiting approval':
        return 'bg-amber-300 bg-opacity-25'; // Amber also suggests something in a pending state.
      case 'rejected':
        return 'bg-red-500 bg-opacity-25'; // Darker red conveys a stronger rejection notice.
      case 'new':
        return 'bg-blue-300 bg-opacity-25'; // Blue often denotes new or informational status.
      default:
        return 'bg-gray-100'; // Default can be a neutral, light gray.
    }
  };

  const buildFirstName = (info) => (
    <div className="pl-3">
      <div className="text-base font-semibold">
        {`${info.row.original.firstname}`}
        {info.row.original.nickname ? ` (${info.row.original.nickname})` : ''}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.auth0_id}
      </div>
    </div>
  );

  const buildSurname = (info) => (
    <div className="pl-3">
      <div className="text-base font-semibold">
        {`${info.row.original.surname}`}
      </div>
      <div className="font-normal text-platform-primary-grey-400">
        {info.row.original.auth0_id}
      </div>
    </div>
  );

  const buildDate = (date) => (
    <div className="flex py-4 min-w-min">
      {date ? dateFormat(date, 'dd-mm-yy') : '-'}
    </div>
  );

  const buildStatus = (info) => (
    <span
      className={`px-3 py-1 justify-center uppercase leading-wide font-bold text-xs rounded-full shadow-sm
          ${
            info.row.original.status.startsWith('active')
              ? 'bg-platform-cta-success-200 text-platform-cta-success-600'
              : info.row.original.status.startsWith('blocked')
              ? 'bg-platform-cta-error-200 text-platform-cta-error-600'
              : info.row.original.status.startsWith('awaiting activation')
              ? 'bg-yellow-100 text-platform-cta-grey-600'
              : info.row.original.status.startsWith('awaiting approval')
              ? 'bg-platform-secondary-orange-200 text-platform-secondary-orange-600'
              : info.row.original.status.startsWith('rejected')
              ? 'bg-platform-cta-error-200 text-platform-cta-error-600'
              : info.row.original.status.startsWith('new')
              ? 'bg-platform-primary-blue-200 text-platform-primary-blue-800'
              : null
          }`}>
      {info.row.original.status}
    </span>
  );

  const buildSenseAttributes = (senseAttributes) => (
    <SenseAttributesPopover senseAttributes={senseAttributes} />
  );

  const buildActionsButtons = (user) => (
    <div className="flex py-4 justify-start">
      <div className="">
        <div className="pb-1">
          <button
            type="button"
            aria-label="edit-user-button"
            disabled
            onClick={() => handleEditUser(user)}
            className="mx-0.5 p-2 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400 disabled:bg-platform-secondary-grey-200">
            <HiPencil />
          </button>
        </div>
        <div className="pb-1">
          <button
            type="button"
            aria-label="block-user-button"
            disabled
            onClick={() => handleBlockUser(user)}
            className="mx-0.5 p-2 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400 disabled:bg-platform-secondary-grey-200">
            <HiMinusCircle />
          </button>
        </div>
      </div>
      <div>
        <div className="pb-1">
          <button
            type="button"
            aria-label="handle-reset-password-button"
            onClick={() => handleResetPassword(user)}
            className="mx-0.5 p-2 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400 disabled:bg-platform-secondary-grey-200">
            <HiMail />
          </button>
        </div>
        <div className="pb-1">
          <button
            type="button"
            aria-label="handle-delete-user-button"
            onClick={() => handleDeleteUser(user)}
            className="mx-0.5 p-2 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400 disabled:bg-platform-secondary-grey-200">
            <HiTrash />
          </button>
        </div>
      </div>
    </div>
  );

  const buildRejection = (info) => (
    <div className="pl-3">
      {info.row.original.date_approval_denied !== null ? (
        <>
          <div className="text-base font-semibold">
            {dateFormat(info.row.original.date_approval_denied, 'dd-mm-yy')}
          </div>
          <div className="font-normal text-platform-primary-grey-400">
            {info.row.original.reason_denied}
          </div>
        </>
      ) : (
        <div className="justify-center text-base font-semibold">-</div>
      )}
    </div>
  );

  const buildSelectRow = (info) => (
    <div className="flex py-4 justify-start items-center">
      <Checkbox.Root
        className="flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-md border border-platform-primary-grey-200 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
        checked={selectedUsers.map((u) => u.id).includes(info.row.original.id)}
        onCheckedChange={() => handleRowSelect(info.row.original)}>
        <Checkbox.Indicator className="">
          <HiCheck />
        </Checkbox.Indicator>
      </Checkbox.Root>
    </div>
  );

  const buildBoolean = (info) => (
    <div className="flex justify-center items-center">
      {info ? (
        <HiCheck className="text-platform-cta-success-800" />
      ) : (
        <HiX className="text-platform-cta-error-800" />
      )}
    </div>
  );

  const buildRequester = (info) => (
    <div className="pl-3">
      <div className="text-base font-semibold">
        {info.row.original.requested_by}
      </div>
    </div>
  );

  const buildApprover = (info) => (
    <div className="pl-3">
      {info.row.original.approver !== null ? (
        <>
          <div className="text-base font-semibold">
            {info.row.original.approver}
          </div>
          <div className="font-normal text-platform-primary-grey-400">
            {dateFormat(info.row.original.date_approval_requested, 'dd-mm-yy')}
          </div>
        </>
      ) : (
        <div className="justify-center text-base font-semibold">-</div>
      )}
    </div>
  );

  const columns = [
    columnHelper.accessor('select', {
      header: (
        <div className="flex py-4 justify-start items-center">
          <Checkbox.Root
            className="flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-md bg-white border border-platform-primary-grey-800 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800 data-[state=indeterminate]:border-platform-primary-orange-800 data-[state=indeterminate]:bg-platform-primary-orange-800"
            checked={selectedUsers.length === mergedUsers.length}
            onCheckedChange={() =>
              selectedUsers.length === mergedUsers.length
                ? setSelectedUsers([])
                : setSelectedUsers(mergedUsers)
            }>
            <Checkbox.Indicator className="">
              {selectedUsers.length === mergedUsers.length ? (
                <HiCheck />
              ) : selectedUsers.length > 0 ? (
                <HiMinus />
              ) : null}
            </Checkbox.Indicator>
          </Checkbox.Root>
        </div>
      ),
      cell: (info) => buildSelectRow(info),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('actions', {
      header: 'Actions',
      cell: (cell) => buildActionsButtons(cell.row.original),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.firstname, {
      id: 'first_name',
      header: 'First Name',
      cell: (info) => buildFirstName(info),
      enableGlobalFilter: true,
      filterFn: 'nameFilterFn',
    }),
    columnHelper.accessor((row) => row.surname, {
      id: 'surname',
      header: 'Surname',
      cell: (info) => buildSurname(info),
      enableGlobalFilter: true,
      filterFn: 'nameFilterFn',
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      header: 'Email',
      cell: (cell) => cell.row?.original?.email,
      enableGlobalFilter: true,
      filterFn: 'emailFilterFn',
    }),
    columnHelper.accessor((row) => row.job_title, {
      id: 'job_title',
      header: 'Job Title',
      cell: (cell) => cell.row?.original?.job_title,
      enableSorting: false,
      filterFn: 'jobTitle',
    }),
    columnHelper.accessor('primary_trust', {
      id: 'primary_trust',
      header: 'Primary Organisation',
      cell: (cell) => cell.row?.original?.primary_trust,
      enableSorting: false,
      filterFn: 'trustFilter',
    }),
    columnHelper.accessor((row) => row.primary_group, {
      id: 'primary_group',
      cell: (info) => info.getValue(),
      header: 'AdviseInc Organisation Group',
      filterFn: 'adviseIncGroupFilter',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => buildStatus(info),
      enableSorting: false,
      filterFn: 'statusFilter',
    }),
    columnHelper.accessor((row) => row.start_date, {
      id: 'startDate',
      cell: (info) => buildDate(info.getValue()),
      header: 'Login Start Date',
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: 'endDate',
      cell: (info) => buildDate(info.getValue()),
      header: 'Login Expiry Date',
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.sense_attributes, {
      id: 'senseAttributes',
      cell: (cell) => buildSenseAttributes(cell.row.original.sense_attributes),
      header: 'Application Access',
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.roles, {
      id: 'roles',
      cell: (cell) =>
        cell.row.original.roles ? cell.row.original.roles.join(', ') : [],
      header: 'Application Permissions',
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.email_verification_sent, {
      id: 'emailVerified',
      cell: (cell) => buildBoolean(cell.row.original.email_verification_sent),
      header: 'Email Verified?',
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.logins_count, {
      id: 'loginsCount',
      cell: (cell) =>
        cell.row.original.logins_count ? cell.row.original.logins_count : 0,
      header: 'Total Logins',
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.last_login, {
      id: 'lastLogin',
      cell: (cell) =>
        cell.row.original.last_login
          ? format(
              parse(
                cell.row.original.last_login,
                "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                new Date(),
              ),
              'dd/MM/yyyy',
            )
          : '',
      header: 'Last Login Date',
      enableColumnFilter: false,
    }),
    columnHelper.accessor('requestedBy', {
      header: 'Requested By',
      cell: (info) => buildRequester(info),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.date_requested, {
      id: 'dateRequested',
      cell: (info) => buildDate(info.getValue()),
      header: 'Requested Date',
      enableColumnFilter: false,
    }),
    columnHelper.accessor('approvedBy', {
      header: 'Internally Approved By',
      cell: (info) => buildApprover(info),
      enableColumnFilter: false,
    }),
    columnHelper.accessor('rejection', {
      header: 'Rejection Details',
      cell: (info) => buildRejection(info),
      enableColumnFilter: false,
    }),
    columnHelper.accessor('notes', {
      header: 'Notes',
      cell: (cell) => cell.row.original.notes,
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data: mergedUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    filterFns: {
      emailFilter: (row, columnId, filterValue) =>
        filterValue.length === 0
          ? row
          : mergedUsers
              .filter((user) =>
                user.email.toLowerCase().includes(filterValue.toLowerCase()),
              )
              .map((user) => user.id)
              .includes(row.original.id),
      adviseIncGroupFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      trustFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      statusFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      nameFilterFn: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      emailFilterFn: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      jobTitle: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
    },
    sortingFns: {
      nameSort: (rowA, rowB) =>
        rowA.original.surname > rowB.original.surname ? 1 : -1,
    },
  });

  return (
    <>
      <Dialog.Root open={showEditUserModal} onOpenChange={setShowEditUserModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
          <EditUserModal
            admin={admin}
            setShowModal={setShowEditUserModal}
            user={selectedUsers[0]}
            ref={ref}
          />
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root
        open={showBlockedUserModal}
        onOpenChange={setShowBlockedUserModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
          <BlockUserModal
            admin={admin}
            setShowModal={setShowBlockedUserModal}
            users={selectedUsers}
            ref={ref}
          />
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root
        open={showResetPasswordModal}
        onOpenChange={setShowResetPasswordModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-platform-primary-grey-800 opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
          <ResetPasswordModal
            admin={admin}
            setShowModal={setShowResetPasswordModal}
            users={selectedUsers}
            ref={ref}
          />
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root
        open={showDeleteUserModal}
        onOpenChange={setShowDeleteUserModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
          <DeleteUserModal
            admin={admin}
            setShowModal={setShowDeleteUserModal}
            usersToDelete={selectedUsers}
            ref={ref}
          />
        </Dialog.Portal>
      </Dialog.Root>
      <UsersTableBar
        admin={admin}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        searchTerm={globalFilter}
        setSearchTerm={setGlobalFilter}
        tableTitle="All Users"
      />
      <div className="max-h-screen  overflow-y-scroll">
        <table className="w-full text-sm text-left text-platform-primary-grey-800">
          <thead className="sticky top-0 text-xs text-platform-primary-grey-800 uppercase bg-platform-secondary-grey-200">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="py-1 px-3 border border-platform-primary-grey-800">
                    {header.isPlaceholder ? null : (
                      <div className="flex flex-col items-center">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div
                          className={`flex items-center ${
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : ''
                          }`}
                          onClick={header.column.getToggleSortingHandler()}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.getIsSorted().toString() === 'asc' ? (
                            <HiChevronUp className="ml-1 text-xl" />
                          ) : header.column.getIsSorted() === 'desc' ? (
                            <HiChevronDown className="ml-1 text-xl" />
                          ) : header.column.getCanSort() ? (
                            <HiDotsHorizontal className="ml-1 text-xl" />
                          ) : null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <input
                              type="text"
                              value={header.column.getFilterValue() ?? ''}
                              onChange={(e) => {
                                header.column.setFilterValue(e.target.value);
                              }}
                              placeholder="Search..."
                              className={`appearance-none block ${
                                header.column.id === 'primary_trust' ||
                                header.column.id === 'email' ||
                                header.column.id === 'job_title' ||
                                header.column.id === 'primary_group'
                                  ? 'w-maxfull'
                                  : 'w-[5rem]' // Reduced width
                              } m-1 py-1 px-2 leading-tight text-start rounded-md bg-white font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                // className="bg-white hover:bg-platform-ainc-grey-400 border border-platform-primary-grey-200"
                className={`hover:bg-platform-ainc-grey-400 border border-platform-primary-grey-200 bg-green ${getStatusColor(
                  row.original.status,
                )}`}
                key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="py-1 px-1.5">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="h-4" />
        <div className="flex items-center justify-center m-2 pb-2">
          <button
            type="button"
            aria-label="pagination-double-left-button"
            className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}>
            <HiChevronDoubleLeft className="text-2xl" />
          </button>
          <button
            type="button"
            aria-label="pagination-left-button"
            className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}>
            <HiChevronLeft className="text-2xl" />
          </button>
          <span className="flex items-center mx-1">
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <button
            type="button"
            aria-label="pagination-right-users-button"
            className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}>
            <HiChevronRight className="text-2xl" />
          </button>
          <button
            type="button"
            aria-label="pagination-double-right-users-button"
            className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}>
            <HiChevronDoubleRight className="text-2xl" />
          </button>
          <span className="flex items-center mx-1">
            Go to page:
            <input
              type="number"
              min="1"
              value={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              disabled={table.getPageCount() < 2}
              className="appearance-none block w-[5rem] mx-1 py-2 px-4 leading-tight text-start rounded-md border border-platform-primary-orange-800 bg-white font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200"
            />
          </span>
          <select
            className="rounded-md border border-platform-primary-orange-800 bg-white text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}>
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
