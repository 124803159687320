import React, { useState, useEffect } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { HiCheck } from 'react-icons/hi';

export default function MultiCheckboxCell({
  fieldName,
  usersToApprove,
  setUsersToApprove,
  defaultValue,
}) {
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  const handleCheckboxChange = (isChecked) => {
    setChecked(isChecked);
    const updatedUsers = usersToApprove.map((user) => {
      user[fieldName] = isChecked;
      return user;
    });
    setUsersToApprove(updatedUsers);
  };

  return (
    <div className="flex group p-2 hover:bg-platform-ainc-grey-200 w-full">
      <Checkbox.Root
        id={`checkbox_${fieldName}`}
        className="flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-lg border border-platform-primary-grey-200 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
        checked={checked}
        onCheckedChange={handleCheckboxChange}>
        <Checkbox.Indicator>
          <HiCheck />
        </Checkbox.Indicator>
      </Checkbox.Root>
    </div>
  );
}
