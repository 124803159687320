import React from 'react';
import { LoaderDots } from './LoaderDots';

function Loader() {
  return (
    <div className="grid place-items-center h-screen bg-platform-ainc-grey-200 opacity-90 z-50">
      <LoaderDots />
      <div className="mt-20 font-main text-platform-primary-grey-200 text-lg font-normal">
        User Management Console Is Loading
      </div>
    </div>
  );
}

export default Loader;
