import React from 'react';

export default function Home() {
  return (
    <div className="bg-white">
      <iframe
        className="flex items-center justify-center w-[90vw] h-[90vh] overflow-y-auto"
        title="Astrato Dashboard"
        src="https://app.astrato.io/embed/bmfsXyI"
      />
    </div>
  );
}
