import React, { forwardRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { HiX } from 'react-icons/hi';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { baseUrl, headers } from '../../app/lookups';
import { useUsersAPI } from '../../app/userContext';
import AlertMessage from '../../components/AlertMessage';

const DeleteUserModal = forwardRef(({ setShowModal, usersToDelete }, ref) => {
  const [requestsResponses, setRequestsResponses] = useState([]);

  const [displayAlert, setDisplayAlert] = useState(false);

  const { users } = useUsersAPI();
  const { user: admin } = useAuth0();

  const handleDelete = async () => {
    const adminId = users
      .filter((u) => u.auth0_id === admin.sub)
      .map((u) => u.id);
    usersToDelete.forEach(async (user) => {
      const responseData = await axios.delete(
        `${baseUrl}/user-service/users?admin_id=${adminId}&auth0_id=${user.auth0_id}&email=${user.email}`,
        { headers },
      );
      setRequestsResponses((prevResponses) => [
        ...prevResponses,
        responseData?.data,
      ]);
      setDisplayAlert(true);
    });
  };

  return (
    <div ref={ref}>
      <Dialog.Content className="w-[40vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="relative w-auto my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
              <div className="text-3xl font-bold text-platform-primary-grey-800">
                Delete Users
              </div>
              <button
                type="button"
                aria-label="close-modal-button"
                className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800  border-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800"
                onClick={() => {
                  setShowModal(false);
                  window.location.reload();
                }}>
                <HiX />
              </button>
            </div>
            <div className="relative p-6 flex-auto max-h-[22rem] overflow-y-auto">
              {usersToDelete.length > 0 ? (
                <p>The following user will be deleted:</p>
              ) : (
                <p>No valid users selected</p>
              )}
              <div>
                {usersToDelete.map((u) => (
                  <li key={u.id}>{u.email}</li>
                ))}
              </div>
              <strong>This procedure cannot be undone!</strong>
            </div>
            <div className="p-2 border-t border-solid border-platform-primary-grey-200 rounded-b">
              {displayAlert &&
                requestsResponses &&
                requestsResponses.length !== 0 &&
                requestsResponses.map((response) =>
                  response?.auth0 && response?.auth0 === 'success' ? (
                    <AlertMessage
                      success
                      message="User has been deleted from Auth0."
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : displayAlert ? (
                    <AlertMessage
                      success={false}
                      message={`User was not deleted from Auth0. ${
                        response.auth0.error && `Error: ${response.auth0.error}`
                      }`}
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : null,
                )}
              {displayAlert &&
                requestsResponses &&
                requestsResponses.length !== 0 &&
                requestsResponses.map((response) =>
                  response.database &&
                  (response?.database?.user?.status === 'success' ||
                    response?.database?.pre_registration?.status ===
                      'success') ? (
                    <AlertMessage
                      success
                      message="User has been deleted from the UM database."
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : displayAlert ? (
                    <AlertMessage
                      success={false}
                      message={`User was not deleted from the UM database. ${
                        response.database.status === 'failure' &&
                        `Error: ${response.database.message}`
                      }`}
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : null,
                )}
              <div className="flex justify-end my-2">
                <button
                  className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                  type="button"
                  aria-label="cancel-button"
                  onClick={() => {
                    setShowModal(false);
                    window.location.reload();
                  }}>
                  Cancel
                </button>
                <button
                  className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                  type="button"
                  aria-label="back-button"
                  onClick={() => handleDelete()}>
                  Delete Users
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </div>
  );
});

export default DeleteUserModal;
