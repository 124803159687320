import React from 'react';

function Logs() {
  return (
    <div className="flex justify-center items-center w-maxFull">
      <iframe
        height={600}
        width={1200}
        src="https://p.datadoghq.eu/sb/7b7b53f0-2ff7-11ec-81fb-da7ad0900005-31c758d6c3033d92b5a06be1368d2795?tpl_var_User=%2A&from_ts=1644420941219&to_ts=1652196941219&live=true"
        title="Usage Logs"
      />
    </div>
  );
}

export default Logs;
