import React, { useState } from 'react';
import UsersTable from './UsersTable';
import PreRegistrationTable from './PreRegistrationTable';
import { UserContextProvider } from '../../app/userContext';
import ApproveUsersTable from './ApproveUsersTable';
import MainTable from './MainTable';

export default function Users({ admin }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  let table;
  if (window.location.pathname === '/users')
    table = (
      <UsersTable
        admin={admin}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
    );
  else if (window.location.pathname === '/pre-registration') {
    table = <PreRegistrationTable admin={admin} />;
  } else if (window.location.pathname === '/all-users') {
    table = (
      <MainTable
        admin={admin}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
    );
  } else if (window.location.pathname === '/users-to-activate') {
    table = (
      <ApproveUsersTable
        admin={admin}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
    );
  } else {
    table = null;
  }

  return <UserContextProvider>{table}</UserContextProvider>;
}
