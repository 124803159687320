import React, { forwardRef, useState } from 'react';
import axios from 'axios';
import { HiX } from 'react-icons/hi';
import * as Dialog from '@radix-ui/react-dialog';
import { format, parse } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import { FormProvider, useForm } from 'react-hook-form';
import UserForm from '../../components/UserForm';
import { baseUrl, headers } from '../../app/lookups';
import AlertMessage from '../../components/AlertMessage';
import { useUsersAPI } from '../../app/userContext';

const EditUserModal = forwardRef(({ setShowModal, user }, ref) => {
  const [response, setResponse] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);

  const { setUsers } = useUsersAPI();
  const { user: admin } = useAuth0();

  const [page, setPage] = useState(1);

  const form = useForm({
    defaultValues: {
      firstname: user.firstname,
      surname: user.surname,
      email: user.email,
      job: user.job_title,
      nickname: user.nickname,
      primaryTrust: user.primary_trust,
      primaryGroup: user.primary_group,
      startDate: format(
        parse(user.start_date, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy',
      ),
      endDate: format(
        parse(user.end_date, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy',
      ),
      senseAttributes: user.sense_attributes,
      roles: user.roles,
      lockToGroup: user.lock_to_group,
      notes: user.notes,
      defaultOrg: user.default_org,
      defaultLandingPage: user.default_landing_page,
      appType: user.app_type,
      picture: user.profile_picture,
    },
  });

  const handleClearForm = () => {
    form.reset({
      firstname: '',
      surname: '',
      email: '',
      job: '',
      nickname: '',
      primaryTrust: '',
      primaryGroup: '',
      startDate: '',
      endDate: '',
      senseAttributes: [],
      roles: [],
      lockToGroup: false,
      notes: '',
      defaultOrg: 'org',
      defaultLandingPage: 'page',
      appType: 'app',
      picture: '',
    });
  };

  const onSubmit = async (data) => {
    setPage(1);
    await form.trigger();
    if (form.formState.isValid) {
      const responseData = await axios.put(
        `${baseUrl}/user-service/users?user_id=${user.id}`,
        {
          email: data.email,
          firstname: data.firstname,
          surname: data.surname,
          start_date: format(
            parse(data.startDate, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd',
          ),
          end_date: format(
            parse(data.endDate, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd',
          ),
          primary_group: data.primaryGroup,
          primary_trust: data.primaryTrust,
          job_title: data.job,
          sense_attributes: data.senseAttributes,
          roles: data.roles,
          lock_to_group: data.lockToGroup,
          notes: data.notes,
          admin_id: `${admin.given_name} ${admin.family_name}`,
          auth0_id: user.auth0_id,
          preferences: {
            default_org: data.defaultOrg,
            default_landing_page: data.defaultLandingPage,
            app_type: data.appType,
            nickname: data.nickname,
            profile_picture: data.picture,
          },
        },
        { headers },
      );
      axios
        .get(
          `${baseUrl}/user-service/users/account-details?auth0_id=${admin.sub}`,
          { headers },
        )
        .then((r) => {
          setUsers(r.data);
        });
      setResponse(responseData.data);
      setDisplayAlert(true);
    }
  };

  const onError = () => {
    setPage(1);
    // console.log('Failure', data, e);
  };

  return (
    <div ref={ref}>
      <Dialog.Content className="w-[70vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="flex justify-center items-center">
          <div className="h-[90vh] rounded-lg shadow-lg flex flex-col w-full bg-white border border-platform-primary-grey-200">
            <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
              <div className="text-3xl font-bold text-platform-primary-grey-800">
                Edit User
              </div>
              <button
                type="button"
                aria-label="edit-user-button"
                className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800  border-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800"
                onClick={() => {
                  setShowModal(false);
                }}>
                <HiX />
              </button>
            </div>
            <FormProvider {...form}>
              <form
                className="overflow-y-scroll"
                onSubmit={form.handleSubmit(onSubmit, onError)}>
                <UserForm form={form} page={page} setPage={setPage} />
                <div className="p-2 border-t border-solid border-platform-primary-grey-200 rounded-b">
                  {displayAlert &&
                  response.auth0 &&
                  response.auth0.status === 'success' ? (
                    <AlertMessage
                      success
                      message={`User ${response.user} has been updated in Auth0.`}
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : displayAlert ? (
                    <AlertMessage
                      success={false}
                      message={`User was not updated in Auth0. ${
                        response.auth0.error && `Error: ${response.auth0.error}`
                      }`}
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : null}
                  {displayAlert &&
                  response.database &&
                  response.database.user.status === 'success' ? (
                    <AlertMessage
                      success
                      message={`${response.user} has been updated in the UM database.`}
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : displayAlert ? (
                    <AlertMessage
                      success={false}
                      message={`User was not updated in the UM database. ${
                        response.database.status === 'failure' &&
                        `Error: ${response.database.message}`
                      }`}
                      closeAlert={() => setDisplayAlert(false)}
                    />
                  ) : null}
                  <div className="flex justify-end my-2">
                    <button
                      className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                      type="button"
                      aria-label="clear-form-button"
                      onClick={() => handleClearForm()}>
                      Clear Form
                    </button>
                    <button
                      className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                      type="submit"
                      aria-label="update-user-button">
                      Update User
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </Dialog.Content>
    </div>
  );
});

export default EditUserModal;
