import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import { HiCheck, HiX } from 'react-icons/hi';
import { qliksenseAttributes } from '../../app/lookups';

export default function SenseAttributesPopover({ senseAttributes }) {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          type="button"
          aria-label="check-button"
          className="mx-0.5 p-2 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
          <HiCheck />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="rounded-lg bg-platform-ainc-grey-200 border border-platform-primary-grey-200"
          side="left">
          <div className="rounded-lg shadow-lg">
            <div className="font-bold p-1 text-platform-primary-grey-800">
              Application Access
            </div>
            <div className="columns-2 py-1 px-2">
              <ul>
                {qliksenseAttributes.map((a) => (
                  <li key={a.id}>
                    <div className="flex items-center">
                      {' '}
                      {senseAttributes && senseAttributes.includes(a.value) ? (
                        <HiCheck className="mr-1 text-platform-cta-success-800" />
                      ) : (
                        <HiX className="mr-1 text-platform-cta-error-800" />
                      )}
                      {a.label}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Popover.Arrow className="fill-platform-primary-grey-200" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
