import React, { useState, useEffect } from 'react';

export default function TableCell({ getValue, row, column, table }) {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);
  const [validationMessage, setValidationMessage] = useState('');

  const displayValidationMessage = (e) => {
    if (columnMeta?.validate) {
      const isValid = columnMeta.validate(e.target.value);
      if (isValid) {
        e.target.setCustomValidity('');
        setValidationMessage('');
      } else {
        e.target.setCustomValidity(columnMeta.validationMessage);
        setValidationMessage(columnMeta.validationMessage);
      }
    } else if (e.target.validity.valid) {
      setValidationMessage('');
    } else {
      setValidationMessage(e.target.validationMessage);
    }
  };

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    } else if (columnMeta?.defaultValue) {
      setValue(columnMeta?.defaultValue);
      tableMeta?.updateData(row.index, column.id, columnMeta?.defaultValue);
    } else if (column.id === 'nickname') {
      tableMeta?.updateData(row.index, column.id, row?.original?.firstname);
    }
  }, [column.id, columnMeta?.defaultValue, initialValue, row, tableMeta]);

  const onBlur = (e) => {
    displayValidationMessage(e);
    tableMeta?.updateData(row.index, column.id, value, e.target.validity.valid);
  };
  return (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      title={validationMessage}
      type={columnMeta?.type || 'text'}
      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    />
  );
}
