import React, { useState, useEffect } from 'react';
import Select from 'react-select';

export default function TableSingleSelectCell({
  getValue,
  row,
  column,
  table,
}) {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (columnMeta?.filterKey) {
      const filteredOptions = columnMeta.options.filter(
        (option) =>
          option[columnMeta.filterKey].toLowerCase() ===
          (row.original[columnMeta.rowKey]?.value?.toLowerCase() ??
            row.original[columnMeta.rowKey]?.toLowerCase()),
      );
      setOptions(filteredOptions);
    } else {
      setOptions(columnMeta?.options || []);
    }
  }, [columnMeta, row]);

  useEffect(() => {
    if (initialValue) {
      const initialSelectedOption = options.find(
        (option) => option?.value.toLowerCase() === initialValue.toLowerCase(),
      );
      setSelectedOption(initialSelectedOption);
    } else if (columnMeta?.defaultValue) {
      const initialSelectedOption = options.find(
        (option) => option?.value.toLowerCase() === initialValue.toLowerCase(),
      );
      setSelectedOption(initialSelectedOption);
      tableMeta?.updateData(row.index, column.id, columnMeta?.defaultValue);
    } else if (selectedOption !== null) {
      tableMeta?.updateData(row.index, column.id, null);
      setSelectedOption(null);
    }
  }, [
    initialValue,
    options,
    row.index,
    column.id,
    columnMeta,
    tableMeta,
    selectedOption,
  ]);

  const onSelectChange = (selected) => {
    tableMeta?.updateData(row.index, column.id, selected?.value || null);
    setSelectedOption(selected);
    if (columnMeta.setCounter) {
      columnMeta.setCounter((prev) => prev + 1);
    }
  };

  return (
    <Select
      options={options}
      hideSelectedOptions={false}
      onChange={onSelectChange}
      className="basic-multi-select"
      classNamePrefix="select"
      value={selectedOption}
      // menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
}
